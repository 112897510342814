import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

const NotFoundPage = () => {
  useEffect(() => {
    const queryParams = window.location.search
    // Redirecting to root for all the misses
    navigate(`/${queryParams}`)
  }, [])

  // Create a fancy 404 page, consider theme and language

  return null
}

export default NotFoundPage
